<template>
  <div>
      <NavBar v-if="type == 1" :list="navList" name="对账管理"></NavBar>
      <Nvalid v-if="type == 2" :list="list"></Nvalid>
      <Nvalid v-if="type == 3" :list="list1"></Nvalid>
      <router-view></router-view>
  </div>
</template>
<script>
import NavBar from "../../../unit/components/navBar.vue"
import Nvalid from "../../../unit/components/nvalid.vue";
export default {
    data() {
        return {
            navList:[
                {name:'线上账单',path:'/finance/accountcheck/lineBill'},
                 {name:'现金账单',path:'/finance/accountcheck/cashBill'}
            ],
            list:[
                {title:'线上账单',url:'/finance/accountcheck/lineBill'},
                {title:'核销记录',url:''}
            ],
             list1:[
                {title:'现金账单',url:'/finance/accountcheck/cashBill'},
                {title:'核销记录',url:''}
            ],
            type:1
        };
    },
    components: { NavBar, Nvalid },
    watch:{
        '$route':{
            handler(e){
                if(e.fullPath == '/finance/accountcheck/lineBill' || e.fullPath == '/finance/accountcheck/cashBill'){
                   this.type =1
                } else if(e.path == '/finance/accountcheck/historyRecord'){
                    this.type = 2
                } else if(e.path == '/finance/accountcheck/cashHistory'){
                    this.type = 3
                }
                // console.log(e.);
            },
            immediate:true
        }
    }
}
</script>
<style lang='scss'>
</style>